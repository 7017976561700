
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/prop-types */
import React from 'react';
import { PagePropsType, regroupModuleReducers, getServerSidePropsLoader } from '@next-common/redux-apollo-helper';

import { renderReact } from '../../fragmentHelper/renderReactCustom';
import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';
// import '@next-common/seller-information-drawer/build/style.global.css';
import SellerInformationDrawerModule from '@next-common/seller-information-drawer';
// import { GetServerSidePropsHypernovaWatchContext } from '../../types';

export const serverCanCache = true;

const { SellerInformationDrawerContainer } = SellerInformationDrawerModule.containers;

// @ts-ignore
const reducers = regroupModuleReducers([SellerInformationDrawerModule]);
// const modules = [SellerInformationDrawerModule]

const WrappedSellerInformationDrawer: React.FC<PagePropsType & { graphQlvariables: any, advertCrewModale: any }> = ({
  initialApolloState,
  initialReduxState,
  moduleName,
}) => {

  return (
    <ReduxApolloFragmentWrapperChildren
      initialApolloState={initialApolloState}
      initialReduxState={initialReduxState}
      moduleName={moduleName}
      reducers={reducers}
    >
      <SellerInformationDrawerContainer />
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const renderFunction = renderReact('SellerInformationDrawer', WrappedSellerInformationDrawer);